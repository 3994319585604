<template>
  <card :title="$t('speakers.info')">
    <form @submit.prevent="update" @keydown="form.onKeydown($event)">
      <alert-success :form="form" :message="$t('speakers.updated')" />

      <speaker-form :form="form"></speaker-form>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-md-9 ml-md-auto">
          <v-button :loading="form.busy" type="success">{{ $t('update') }}</v-button>
        </div>
      </div>
    </form>
  </card>
</template>

<script>
import Form from "vform"
import SpeakerForm from "./form"
import { mapGetters } from "vuex"
import { objectToFormData } from 'object-to-formdata'

export default {
  scrollToTop: false,
  components: {
    "speaker-form": SpeakerForm
  },
  data: () => ({
    form: new Form({
      name: "",
      thumbnailUrl: "",
      thumbnail: null,
    })
  }),
  created() {
    const speaker = this.speakers[this.$route.params.id];

    if (speaker) {
      this.refresh(speaker);
    } else {
      this.$store.dispatch('account/fetchSpeakers');
    }
  },
  computed: {
    ...mapGetters({
      speakers: "account/speakers"
    })
  },
  methods: {
    refresh(speaker) {
      this.form.keys().forEach(key => {
        this.form[key] = speaker[key];
      });
    },
    async update() {
      const { data } = await this.form.submit('post','/api/v1/user/me/speakers/' + this.$route.params.id, {
        // Transform form data to FormData
        transformRequest: [function(data) {
          data._method = "put";
          return objectToFormData(data);
        }]
      });
      
      const speaker = data.speaker;

      // Update the store
      this.$store.dispatch("account/updateSpeakers", { speaker: speaker });

      // Update the form
      this.refresh(speaker);
    }
  }
};
</script>
